import $ from 'jquery';
import * as app from "../../../../../fetch/fetchApi";

const isBrowser = typeof window !== "undefined"
import TagManager from 'react-gtm-module'

if (isBrowser) {
    $(document).ready(
        function () {
            const tagManagerArgs = {
                gtmId: app.gtmCode
            }
            TagManager.initialize(tagManagerArgs)
            setTimeout(() => {
            }, 12000);

            function noches_hospedajes() {
                var id = window.location.href
                var url = new URL(id);
                var fechaInicio = url.searchParams.get("fechaInicio");
                var fechaFin = url.searchParams.get("fechaFin");
                var newFI = fechaInicio.replace(/-/g, '/')
                var newFF = fechaFin.replace(/-/g, '/')
                var dayInicio = new Date(newFI);
                var dayFin = new Date(newFF);
                var fechaInicioR = newFI.split("/").reverse().join("/")
                var fechaFinR = newFF.split("/").reverse().join("/")
                var diff = dayFin - dayInicio;
                const weekday = ["Domingo", "Lunes", "Martes", "Miercoles", "Jueves", "Viernes", "Sabado"];
                const d = new Date();
                let day = weekday[d.getDay()];
                window.dataLayer.push({
                    'event': 'nochesHospedadas',
                    'startDate': fechaInicioR,
                    'endDate': fechaFinR,
                    'startDay': weekday[dayInicio.getDay()],
                    'endDay': weekday[dayFin.getDay()],
                    'qtyNight': diff / (1000 * 60 * 60 * 24),
                    'qtyHab': '1'
                })
            }

            function addToCart() {
                let id = window.location.href
                var url = new URL(id);
                var formData = []
                var arreglo = [];
                var eventoEnviado = false;
                if (url.pathname.includes('reserva')) {
                    var datesCamping = document.querySelectorAll('#cantidadAdultoCamping');
                    for (let index = 0; index < datesCamping.length; index++) {
                        if (datesCamping[index].attributes.name !== undefined) {
                            arreglo.push(parseInt(datesCamping[index].textContent.trim()))
                            var row = {
                                item_name: datesCamping[index].getAttribute('data-nombre'),
                                item_id: datesCamping[index].getAttribute('data-sku'),
                                price: datesCamping[index].getAttribute('data-precio').replace('$', ''),
                                item_brand: 'Las estacas',
                                item_category: "hospedaje",
                                item_variant: "general",
                                item_list_name: "reservas",
                                item_list_id: "lasEstacas",
                                index: 1,
                                quantity: datesCamping[index].textContent.trim(),
                            }
                            formData.push(row)
                        }
                    }
                    var datesCamping2 = document.querySelectorAll('#cantidadNinioCamping');
                    for (let index = 0; index < datesCamping2.length; index++) {
                        if (datesCamping2[index].attributes.name !== undefined) {
                            if (datesCamping2[index].textContent.trim() !== "0") {
                                arreglo.push(parseInt(datesCamping2[index].textContent.trim()))
                                var row = {
                                    item_name: datesCamping2[index].getAttribute('data-nombre'),
                                    item_id: datesCamping2[index].getAttribute('data-sku'),
                                    price: datesCamping2[index].getAttribute('data-precio').replace('$', ''),
                                    item_brand: 'Las estacas',
                                    item_category: "hospedaje",
                                    item_variant: "menores",
                                    item_list_name: "reservas",
                                    item_list_id: "lasEstacas",
                                    index: 1,
                                    quantity: datesCamping2[index].textContent.trim(),
                                }
                            }

                        }
                    }
                    var dates = document.querySelectorAll('[id^=cantidadAdicional]');
                    for (let index = 0; index < dates.length; index++) {
                        if (dates[index].attributes.name !== undefined) {
                            arreglo.push(parseInt(dates[index].textContent))
                            var row = {
                                item_name: dates[index].getAttribute('data-nombre'),
                                item_id: dates[index].attributes.name.nodeValue,
                                price: dates[index].getAttribute('data-precio'),
                                item_brand: 'Las estacas',
                                item_category: "Actividades",
                                item_variant: "actividades",
                                item_list_name: "reservas",
                                item_list_id: "lasEstacas",
                                index: 1,
                                quantity: dates[index].textContent,
                            }
                            formData.push(row)
                        }
                    }

                    var datesHabitacion = document.querySelectorAll('[id^=precioHabitacion]');
                    for (let index = 0; index < datesHabitacion.length; index++) {
                        if (datesHabitacion[index].attributes.name !== undefined) {
                            if (datesHabitacion[index].attributes.name.nodeValue !== $('#precioHabitacionCamping').attr('name')) {
                                if (datesHabitacion[index].attributes.name.nodeValue == '344943' || datesHabitacion[index].attributes.name.nodeValue == '346246' || datesHabitacion[index].attributes.name.nodeValue == '353932' || datesHabitacion[index].attributes.name.nodeValue == '367955') {
                                    arreglo.push(parseInt(1))
                                    var row = {
                                        id: datesHabitacion[index].attributes.name.nodeValue,
                                        nombre: datesHabitacion[index].getAttribute('data-nombre'),
                                        cantidad: 1
                                    }
                                    formData.push(row)
                                } else {
                                    arreglo.push(parseInt(1))
                                    var row = {
                                        item_name: datesHabitacion[index].getAttribute('data-nombre'),
                                        item_id: datesHabitacion[index].attributes.name.nodeValue,
                                        price: datesHabitacion[index].textContent.replace('$', ''),
                                        item_brand: 'Las estacas',
                                        item_category: "hospedaje",
                                        item_variant: "entradas",
                                        item_list_name: "reservas",
                                        item_list_id: "lasEstacas",
                                        index: 1,
                                        quantity: 1,
                                    }
                                    formData.push(row)
                                }
                            }

                        }
                    }
                } else {
                    var dates = document.querySelectorAll('*[id^="edit-field-"]');
                    var formData = []
                    var arreglo = [];
                    for (let index = 0; index < dates.length; index++) {
                        arreglo.push(parseInt(dates[index].value))
                        if (dates[index].value > 0) {
                            var row = {
                                id: dates[index].getAttribute('data-sku'),
                                nombre: dates[index].getAttribute('data-titulo'),
                                cantidad: dates[index].value,
                                precio: dates[index].getAttribute('data-precio')
                            }
                            formData.push(row)
                        }

                    }
                    const someIsNotZero = arreglo.some(item => item !== 0);
                    const isAllZero = !someIsNotZero;
                    const isAllZero2 = arreglo.every(item => item === 0);
                    var datos = JSON.stringify(formData, 4, null)
                    if (isAllZero == false && formData.length) {
                        let id = window.location.href
                        var url = new URL(id);
                        var categoria = url.pathname
                        var cat = '';
                        var variante = '';
                        var arrayCat = categoria.split('/')
                        var items = [];

                        if (categoria.includes('atracciones')) {
                            cat = 'Atracciones'
                        }
                        if (categoria.includes('hospedaje')) {
                            cat = 'Hospedaje'
                        }
                        if (categoria.includes('spa')) {
                            cat = 'Spa'
                        }
                        if (categoria.includes('reserva')) {
                            cat = 'Reserva'
                        }
                        for (let index = 0; index < formData.length; index++) {

                            if (formData[index].nombre.includes('1.25')) {
                                variante = 'Menores de 1.25m'
                            }
                            if (formData[index].nombre.includes('90')) {
                                variante = 'Menores de 90cm'
                            }
                            if (formData[index].nombre.includes('menor')) {
                                variante = 'Menores'
                            }
                            if (!formData[index].nombre.includes('1.25')) {
                                variante = 'General'
                            }
                            if (formData[index].nombre.includes('Entrada')) {
                                variante = 'Entradas'
                            }
                            items.push({
                                item_name: formData[index].nombre,
                                item_id: formData[index].id,
                                price: formData[index].precio.includes('$') !== undefined ? formData[index].precio.replace('$', '') : formData[index].precio,
                                item_brand: 'Las Estacas',
                                item_category: cat,
                                item_variant: variante,
                                item_list_name: cat + ' ' + arrayCat[arrayCat.length - 1] + ' ' + formData[index].comp,
                                item_list_id: "lasEstacas",
                                index: 1,
                                quantity: formData[index].cantidad
                            })
                            // window.dataLayer.push({ ecommerce: null });
                            // window.dataLayer.push({
                            //     'event': 'add_to_cart',
                            //     ecommerce: {
                            //         items: [{                        //  adding a product to a shopping cart.
                            //             item_name: formData[index].nombre,
                            //             item_id: formData[index].id,
                            //             price: formData[index].precio.includes('$') !== undefined ? formData[index].precio.replace('$', '') : formData[index].precio,
                            //             item_brand: 'Las Estacas',
                            //             item_category: cat,
                            //             item_variant: variante,
                            //             item_list_name: cat + ' ' + arrayCat[arrayCat.length - 1] + ' ' + formData[index].comp,
                            //             item_list_id: "lasEstacas",
                            //             index: 1,
                            //             quantity: formData[index].cantidad
                            //         }]
                            //     }

                            // });
                        }
                        window.dataLayer.push({ ecommerce: null });
                        window.dataLayer.push({
                            'event': 'add_to_cart',
                            ecommerce: {
                                items: items
                            }
                        });
                        eventoEnviado = true;
                    }

                }
                if ($("#idModal") && !eventoEnviado) {
                    var cantidad = $("#edit-quantity2").val() > 0 ? $("#edit-quantity2").val() : $("#edit-quantity").val()
                    if (cantidad > 0) {
                        var modal = document.getElementById("myModal2");
                        formData = [{
                            id: $("#idModal").text(),
                            nombre: $('#tituloModal2').text(),
                            cantidad: cantidad,
                            precio: $('#precioModal2').text(),
                            sku: $('#sku2').text()
                        }];
                        arreglo.push(parseInt(cantidad))
                        var datos = JSON.stringify(formData, 4, null)
                    }
                    const someIsNotZero = arreglo.some(item => item !== 0);
                    const isAllZero = !someIsNotZero;
                    const isAllZero2 = arreglo.every(item => item === 0);
                    var datos = JSON.stringify(formData, 4, null)
                    if (isAllZero == false) {
                        let id = window.location.href
                        var url = new URL(id);
                        var categoria = url.pathname
                        var cat = '';
                        var variante = '';
                        var arrayCat = categoria.split('/')
                        var items = [];

                        if (categoria.includes('atracciones')) {
                            cat = 'Atracciones'
                        }
                        if (categoria.includes('hospedaje')) {
                            cat = 'Hospedaje'
                        }
                        if (categoria.includes('spa')) {
                            cat = 'Spa'
                        }
                        if (categoria.includes('reserva')) {
                            cat = 'Reserva'
                        }
                        for (let index = 0; index < formData.length; index++) {

                            if (formData[index].nombre.includes('1.25')) {
                                variante = 'Menores de 1.25m'
                            }
                            if (formData[index].nombre.includes('90')) {
                                variante = 'Menores de 90cm'
                            }
                            if (formData[index].nombre.includes('menor')) {
                                variante = 'Menores'
                            }
                            if (!formData[index].nombre.includes('1.25')) {
                                variante = 'General'
                            }
                            if (formData[index].nombre.includes('Entrada')) {
                                variante = 'Entradas'
                            }
                            items.push({
                                item_name: formData[index].nombre,
                                item_id: formData[index].id,
                                price: formData[index].precio.includes('$') !== undefined ? formData[index].precio.replace('$', '') : formData[index].precio,
                                item_brand: 'Las Estacas',
                                item_category: cat,
                                item_variant: variante,
                                item_list_name: cat + ' ' + arrayCat[arrayCat.length - 1] + ' ' + formData[index].comp,
                                item_list_id: "lasEstacas",
                                index: 1,
                                quantity: formData[index].cantidad
                            })
                            // window.dataLayer.push({ ecommerce: null });
                            // window.dataLayer.push({
                            //     'event': 'add_to_cart',
                            //     ecommerce: {
                            //         items: [{                        //  adding a product to a shopping cart.
                            //             item_name: formData[index].nombre,
                            //             item_id: formData[index].id,
                            //             price: formData[index].precio.includes('$') !== undefined ? formData[index].precio.replace('$', '') : formData[index].precio,
                            //             item_brand: 'Las Estacas',
                            //             item_category: cat,
                            //             item_variant: variante,
                            //             item_list_name: cat + ' ' + arrayCat[arrayCat.length - 1] + ' ' + formData[index].comp,
                            //             item_list_id: "lasEstacas",
                            //             index: 1,
                            //             quantity: formData[index].cantidad
                            //         }]
                            //     }
                            // });
                        }
                        window.dataLayer.push({ ecommerce: null });
                        window.dataLayer.push({
                            'event': 'add_to_cart',
                            ecommerce: {
                                items: items
                            }
                        });
                    }
                }
            }

            function addNewsletter() {
                window.dataLayer.push({
                    'event': 'addNewsletter',
                    'nombre': $('#edit-field-nombre-und-0-value--2').val(),
                    'correo': $('#edit-field-email-und-0-email').val(),
                    'fechaNacimiento': $('#fecha').val()
                })
            }

            function selectItem(element) {
                var atributos = element.attributes;
                window.dataLayer.push({ ecommerce: null });
                window.dataLayer.push({
                    event: "select_item",
                    ecommerce: {
                        items: [{
                            item_name: atributos["data-nombre"].value,
                            item_id: atributos["data-id"].value,
                            item_brand: 'Las Estacas',
                            item_category: atributos["data-cat"].value,
                            item_variant: atributos["data-var"].value,
                            index: 1,
                            quantity: 1,
                            price: atributos["data-precio"].value.replace('$', ''),
                        }]
                    }
                });
            }

            function viewItem() {
                let urlCompleta = window.location.href
                var urlSitio = new URL(urlCompleta);
                if (urlSitio.pathname.includes('atracciones/') && !urlSitio.pathname.includes("glamping") && !urlSitio.pathname.includes("hotel")) {
                    var elemento = document.getElementById('tituloInteres')
                    var elementoProducto = document.querySelectorAll('.labelReservacion#productos')
                    var atributos = elementoProducto[elementoProducto.length - 1].attributes;
                    var categoria = url.pathname
                    var cat = '';
                    var variante
                    var arrayCat = categoria.split('/')

                    if (categoria.includes('atracciones')) {
                        cat = 'atracciones'
                    }
                    if (categoria.includes('hospedaje')) {
                        cat = 'hospedaje'
                    }
                    if (categoria.includes('spa')) {
                        cat = 'spa'
                    }
                    if (categoria.includes('reserva')) {
                        cat = 'reserva'
                    }

                    if (elementoProducto[elementoProducto.length - 1].textContent.includes('1.25')) {
                        variante = 'menores de 1.25m'
                    }
                    if (elementoProducto[elementoProducto.length - 1].textContent.includes('90')) {
                        variante = 'menores de 90cm'
                    }
                    if (elementoProducto[elementoProducto.length - 1].textContent.includes('menor')) {
                        variante = 'menores'
                    }
                    if (!elementoProducto[elementoProducto.length - 1].textContent.includes('1.25')) {
                        variante = 'general'
                    }
                    if (elementoProducto[elementoProducto.length - 1].textContent.includes('Entrada')) {
                        cat = 'entradas'
                    } else {
                        variante = ""
                    }
                    if (!atributos["data-sku"].value.includes("Entrada")) {
                        window.dataLayer.push({
                            event: "view_item",
                            ecommerce: {
                                items: [{
                                    item_name: atributos["data-nombre"].value, // Name or ID is required.
                                    item_id: atributos["data-sku"].value,
                                    price: atributos["data-precio"].value.replace('$', ''),
                                    item_brand: "Las estacas",
                                    item_category: cat,
                                    item_variant: variante,
                                    quantity: 1
                                }]
                            }
                        });
                    }
                }
                if (urlSitio.pathname.includes('reserva')) {
                    var elemento = document.getElementById('tituloInteres')
                    var elementoProducto = document.querySelectorAll('.reserv#productos')
                    var atributos = elementoProducto[elementoProducto.length - 1].attributes;
                    var categoria = url.pathname
                    var cat = '';
                    var variante
                    var arrayCat = categoria.split('/')

                    if (categoria.includes('atracciones')) {
                        cat = 'atracciones'
                    }
                    if (categoria.includes('hospedaje')) {
                        cat = 'hospedaje'
                    }
                    if (categoria.includes('spa')) {
                        cat = 'spa'
                    }
                    if (categoria.includes('reserva')) {
                        cat = 'reserva'
                    }
                    if (elementoProducto[elementoProducto.length - 1].textContent.includes('1.25')) {
                        variante = 'menores de 1.25m'
                    }
                    if (elementoProducto[elementoProducto.length - 1].textContent.includes('90')) {
                        variante = 'menores de 90cm'
                    }
                    if (elementoProducto[elementoProducto.length - 1].textContent.includes('menor')) {
                        variante = 'menores'
                    }
                    if (!elementoProducto[elementoProducto.length - 1].textContent.includes('1.25')) {
                        variante = 'general'
                    }
                    if (elementoProducto[elementoProducto.length - 1].textContent.includes('Entrada')) {
                        cat = 'entradas'
                    } else {
                        variante = ""
                    }
                    if (!atributos["data-sku"].value.includes("Entrada")) {
                        window.dataLayer.push({
                            event: "view_item",
                            ecommerce: {
                                items: [{
                                    item_name: atributos["data-nombre"].value, // Name or ID is required.
                                    item_id: atributos["data-sku"].value,
                                    price: atributos["data-precio"].value.replace('$', ''),
                                    item_brand: "Las estacas",
                                    item_category: cat,
                                    item_variant: variante,
                                    quantity: 1
                                }]
                            }
                        });
                    }
                }
            }

            function addContacto() {
                window.dataLayer.push({
                    'event': 'addContacto',
                    'nombre': $('#nombreCompleto').val(),
                    'telefono': $('#telefono').val(),
                    'correo': $('#email').val(),
                    'mensaje': $('#comentario').val()

                })
            }

            document.addEventListener('click', function (e) {
                var id = e.target.id

                if (e.target && id.includes('siguiente3')) {
                    addToCart()
                    noches_hospedajes()
                }
                if (e.target && id.includes('sendMail')) {
                    addNewsletter()
                }
                if (e.target && id.includes('enviarFormulario')) {
                    addContacto()
                }
                if (e.target && id.includes('author_bio_wrap_toggle')) {
                }
                if (e.target && e.target.classList.contains('select-item')) {
                    selectItem(e.target);
                }
            })

            window.addEventListener("load", function () {
                setTimeout(() => {
                    var lazyBackgrounds = [].slice.call(document.querySelectorAll(".lazy"));
                    if ("IntersectionObserver" in window && "IntersectionObserverEntry" in window && "intersectionRatio" in window.IntersectionObserverEntry.prototype) {
                        let lazyBackgroundObserver = new IntersectionObserver(function (entries, observer) {
                            entries.forEach(function (entry) {
                                if (entry.isIntersecting) {
                                    entry.target.classList.add("visible");
                                    lazyBackgroundObserver.unobserve(entry.target);
                                }
                            });
                        });

                        lazyBackgrounds.forEach(function (lazyBackground) {
                            lazyBackgroundObserver.observe(lazyBackground);
                        });
                    }
                }, 1000);

            });
            setTimeout(() => {
                window.dataLayer = window.dataLayer || [];
                $("#agregarCarrito").click(function () {
                    addToCart();
                });
                $("#agregarCarritoCamping").click(function () {
                    addToCart();
                });

                $("#agregarCarritoComplementa").click(function () {
                    addToCart();
                });

                $("#formularioResumen").submit(function () {
                    addToCart();
                    noches_hospedajes();
                })

                $("#siguiente3").click(function () {
                    var formData = []
                    var arreglo = [];
                    addToCart();
                    noches_hospedajes();
                });

                $(".sendMail").click(function () {
                    addNewsletter();

                });
                $(".enviarFormulario").click(function () {
                    addContacto();
                });
            }, 3000);

            var id = window.location.href

            var url = new URL(id);
            if (url.pathname.includes('reserva')) {
                setTimeout(() => {
                    $(document).ready(function () {
                        var formData = [];
                        var arreglo = []
                        var arregloItem = []
                        arreglo.push(1)
                        var formulario = document.querySelectorAll('#productos.reserv');
                        for (let index = 0; index < formulario.length; index++) {
                            var row = {
                                nombre: formulario[index].getAttribute('data-nombre'),
                                comp: formulario[index].getAttribute('data-componente'),
                                sku: formulario[index].getAttribute('data-sku'),
                                precio: formulario[index].getAttribute('data-precio')
                            }
                            formData.push(row)
                            const someIsNotZero = arreglo.some(item => item !== 0);
                            const isAllZero = !someIsNotZero;
                            const isAllZero2 = arreglo.every(item => item === 0);
                            var datos = JSON.stringify(formData, 4, null)
                            let id = window.location.href
                            var url = new URL(id);
                            var categoria = url.pathname
                            var cat = '';
                            var variante = '';
                            var arrayCat = categoria.split('/')

                            if (categoria.includes('atracciones')) {
                                cat = 'Atracciones'
                            }
                            if (categoria.includes('hospedaje')) {
                                cat = 'Hospedaje'
                            }
                            if (categoria.includes('spa')) {
                                cat = 'Spa'
                            }
                            if (categoria.includes('reserva')) {
                                cat = 'Hospedaje'
                            }

                            if (formulario[index].getAttribute('data-componente').includes('card disponibilidad')) {
                                if (formulario[index].getAttribute('data-nombre').includes('Glamping')) {
                                    variante = 'Glamping';
                                } else if (formulario[index].getAttribute('data-nombre').includes('Camping')) {
                                    variante = 'Camping';
                                } else {
                                    variante = 'Hotel Las Estacas';
                                }
                            }

                            if (formulario[index].getAttribute('data-nombre').includes('1.25')) {
                                variante = 'Menores de 1.25m'
                            }
                            if (formulario[index].getAttribute('data-nombre').includes('90')) {
                                variante = 'Menores de 90cm'
                            }
                            if (formulario[index].getAttribute('data-nombre').includes('menor') || formulario[index].getAttribute('data-nombre').includes('Menor')) {
                                variante = 'Menores'
                            }
                            if (!formulario[index].getAttribute('data-nombre').includes('1.25')) {
                                variante = 'General'
                            }
                            if (formulario[index].getAttribute('data-nombre').includes('Entrada')) {
                                variante = 'Entradas'
                            }

                            var rowItem = {
                                item_name: formulario[index].getAttribute('data-nombre'),
                                item_id: formulario[index].getAttribute('data-sku'),
                                price: formulario[index].getAttribute('data-precio').replace('$', ''),
                                item_brand: 'Las Estacas',
                                item_category: cat,
                                item_variant: variante,
                                item_list_name: variante + ' ' + cat,
                                item_list_id: variante.charAt(0) + cat.charAt(0),
                                index: '1',
                                quantity: '1'
                            }
                            arregloItem.push(rowItem)
                            // Comentado porque puede ser que se necesite un evento por cada producto.
                            // window.dataLayer.push({
                            //     'event': 'productImpressions',
                            //     'item': formulario[index].getAttribute('data-nombre'),
                            //     'category': cat,
                            //     'brand': 'Las estacas',
                            //     'variants': variante,
                            //     'url': categoria,
                            //     'lista': cat + ' ' + arrayCat[arrayCat.length - 1] + ' ' + formulario[index].getAttribute('data-componente'),
                            //     ecommerce: {
                            //         items:
                            //             [
                            //                 {
                            //                     item_name: formulario[index].getAttribute('data-nombre'),
                            //                     item_id: formulario[index].getAttribute('data-sku'),
                            //                     price: formulario[index].getAttribute('data-precio').replace('$', ''),
                            //                     item_brand: 'Las estacas',
                            //                     item_category: cat,
                            //                     item_variant: variante,
                            //                     tiem_list_name: cat + ' ' + arrayCat[arrayCat.length - 1] + ' ' + formulario[index].getAttribute('data-componente'),
                            //                     index: '1',
                            //                     quantity: '1'
                            //                 }]
                            //     }
                            // })
                        }
                        // Envío del evento 'view_item_list'.
                        window.dataLayer.push({ ecommerce: null });
                        window.dataLayer.push({
                            event: 'view_item_list',
                            ecommerce: {
                                items: arregloItem
                            }
                        });
                    })

                }, 10000);
            } else {
                setTimeout(() => {
                    $(document).ready(function () {
                        var formData = [];
                        var arreglo = []
                        var arregloItem = []
                        arreglo.push(1)
                        var formulario = document.querySelectorAll('#productos.gtm');
                        for (let index = 0; index < formulario.length; index++) {
                            var row = {
                                nombre: formulario[index].getAttribute('data-nombre'),
                                comp: formulario[index].getAttribute('data-componente'),
                                sku: formulario[index].getAttribute('data-sku'),
                                precio: formulario[index].getAttribute('data-precio')
                            }
                            formData.push(row)
                            const someIsNotZero = arreglo.some(item => item !== 0);
                            const isAllZero = !someIsNotZero;
                            const isAllZero2 = arreglo.every(item => item === 0);
                            var datos = JSON.stringify(formData, 4, null)
                            let id = window.location.href
                            var url = new URL(id);
                            var categoria = url.pathname
                            var cat = 'Home';
                            var variante = '';
                            var arrayCat = categoria.split('/')

                            if (categoria.includes('atracciones')) {
                                cat = 'Atracciones'
                            }
                            if (categoria.includes('hospedaje')) {
                                cat = 'Hospedaje'
                            }
                            if (categoria.includes('spa')) {
                                cat = 'Spa'
                            }
                            if (categoria.includes('reserva')) {
                                cat = 'Hospedaje'
                            }

                            if (formulario[index].getAttribute('data-nombre').includes('1.25')) {
                                variante = 'Menores de 1.25m'
                            }
                            if (formulario[index].getAttribute('data-nombre').includes('90')) {
                                variante = 'Menores de 90cm'
                            }
                            if (formulario[index].getAttribute('data-nombre').includes('menor') || formulario[index].getAttribute('data-nombre').includes('Menor')) {
                                variante = 'Menores'
                            }
                            if (!formulario[index].getAttribute('data-nombre').includes('1.25')) {
                                variante = 'General'
                            }
                            if (formulario[index].getAttribute('data-nombre').includes('Entrada')) {
                                variante = 'Entradas'
                            }
                            var rowItem = {
                                item_name: formulario[index].getAttribute('data-nombre'),
                                item_id: formulario[index].getAttribute('data-sku'),
                                price: formulario[index].getAttribute('data-precio').replace('$', ''),
                                item_brand: 'Las Estacas',
                                item_category: cat,
                                item_variant: variante,
                                item_list_name: 'Formulario Inicial ' + cat,
                                item_list_id: 'FI' + cat.charAt(0),
                                index: '1',
                                quantity: '1'
                            }
                            arregloItem.push(rowItem)

                            // Comentado porque puede ser que se necesite un evento por cada producto.
                            // window.dataLayer.push({
                            //     'event': 'productImpressions',
                            //     'item': formulario[index].getAttribute('data-nombre'),
                            //     'category': cat,
                            //     'brand': 'Las estacas',
                            //     'variants': variante,
                            //     'url': categoria,
                            //     'lista': cat + ' ' + arrayCat[arrayCat.length - 1] + ' ' + formulario[index].getAttribute('data-componente'),
                            //     ecommerce: {
                            //         items:
                            //             [
                            //                 {
                            //                     item_name: formulario[index].getAttribute('data-nombre'),
                            //                     item_id: formulario[index].getAttribute('data-sku'),
                            //                     price: formulario[index].getAttribute('data-precio').replace('$', ''),
                            //                     item_brand: 'Las estacas',
                            //                     item_category: cat,
                            //                     item_variant: variante,
                            //                     tiem_list_name: cat + ' ' + arrayCat[arrayCat.length - 1] + ' ' + formulario[index].getAttribute('data-componente'),
                            //                     index: '1',
                            //                     quantity: '1'
                            //                 }]
                            //     }
                            // })
                        }
                        // Envío del evento 'view_item_list'.
                        window.dataLayer.push({ ecommerce: null });
                        window.dataLayer.push({
                            event: 'view_item_list',
                            ecommerce: {
                                items: arregloItem
                            }
                        });

                        var formData2 = [];
                        var arreglo2 = []
                        var arregloItem2 = []
                        arreglo2.push(1)
                        var formulario2 = document.querySelectorAll('#productos.complementaItem');
                        if (formulario2.length > 0) {
                            for (let index = 0; index < formulario2.length; index++) {
                                var row = {
                                    nombre: formulario2[index].getAttribute('data-nombre'),
                                    comp: formulario2[index].getAttribute('data-componente'),
                                    sku: formulario2[index].getAttribute('data-sku'),
                                    precio: formulario2[index].getAttribute('data-precio')
                                }
                                formData2.push(row)
                                const someIsNotZero = arreglo2.some(item => item !== 0);
                                const isAllZero = !someIsNotZero;
                                const isAllZero2 = arreglo2.every(item => item === 0);
                                var datos = JSON.stringify(formData2, 4, null)
                                let id = window.location.href
                                var url = new URL(id);
                                var categoria = url.pathname
                                var cat = '';
                                var variante = '';
                                var arrayCat = categoria.split('/')

                                if (categoria.includes('atracciones')) {
                                    cat = 'Atracciones'
                                }
                                if (categoria.includes('hospedaje')) {
                                    cat = 'Hospedaje'
                                }
                                if (categoria.includes('spa')) {
                                    cat = 'Spa'
                                }
                                if (categoria.includes('reserva')) {
                                    cat = 'Hospedaje'
                                }
                                if (formulario2[index].getAttribute('data-nombre').includes('1.25')) {
                                    variante = 'Menores de 1.25m'
                                }
                                if (formulario2[index].getAttribute('data-nombre').includes('90')) {
                                    variante = 'Menores de 90cm'
                                }
                                if (formulario2[index].getAttribute('data-nombre').includes('menor') || formulario2[index].getAttribute('data-nombre').includes('Menor')) {
                                    variante = 'Menores'
                                }
                                if (!formulario2[index].getAttribute('data-nombre').includes('1.25')) {
                                    variante = 'General'
                                }
                                if (formulario2[index].getAttribute('data-nombre').includes('Entrada')) {
                                    variante = 'Entradas'
                                }
                                var rowItem = {
                                    item_name: formulario2[index].getAttribute('data-nombre'),
                                    item_id: formulario2[index].getAttribute('data-sku'),
                                    price: formulario2[index].getAttribute('data-precio').replace('$', ''),
                                    item_brand: 'Las Estacas',
                                    item_category: cat,
                                    item_variant: variante,
                                    item_list_name: 'Complementos ' + cat,
                                    item_list_id: 'C' + cat.charAt(0),
                                    index: '1',
                                    quantity: '1'
                                }
                                arregloItem2.push(rowItem)

                                // Comentado porque puede ser que se necesite un evento por cada producto.
                                // window.dataLayer.push({
                                //     'event': 'productImpressions',
                                //     'item': formulario2[index].getAttribute('data-nombre'),
                                //     'category': cat,
                                //     'brand': 'Las estacas',
                                //     'variants': variante,
                                //     'url': categoria,
                                //     'lista': cat + ' ' + arrayCat[arrayCat.length - 1] + ' ' + formulario2[index].getAttribute('data-componente'),
                                //     ecommerce: {
                                //         items:
                                //             [
                                //                 {
                                //                     item_name: formulario2[index].getAttribute('data-nombre'),
                                //                     item_id: formulario2[index].getAttribute('data-sku'),
                                //                     price: formulario2[index].getAttribute('data-precio').replace('$', ''),
                                //                     item_brand: 'Las estacas',
                                //                     item_category: cat,
                                //                     item_variant: variante,
                                //                     tiem_list_name: cat + ' ' + arrayCat[arrayCat.length - 1] + ' ' + formulario2[index].getAttribute('data-componente'),
                                //                     index: '1',
                                //                     quantity: '1'
                                //                 }]
                                //     }
                                // })
                            }
                            // Envío del evento 'view_item_list'.
                            window.dataLayer.push({ ecommerce: null });
                            window.dataLayer.push({
                                event: 'view_item_list',
                                ecommerce: {
                                    items: arregloItem2
                                }
                            });
                        }

                        var formData3 = [];
                        var arreglo3 = []
                        var arregloItem3 = []
                        arreglo3.push(1)
                        var formulario3 = document.querySelectorAll('#productos.productos');
                        for (let index = 0; index < formulario3.length; index++) {
                            var row = {
                                nombre: formulario3[index].getAttribute('data-nombre'),
                                comp: formulario3[index].getAttribute('data-componente'),
                                sku: formulario3[index].getAttribute('data-sku'),
                                precio: formulario3[index].getAttribute('data-precio')
                            }
                            formData3.push(row)
                            const someIsNotZero = arreglo3.some(item => item !== 0);
                            const isAllZero = !someIsNotZero;
                            const isAllZero2 = arreglo3.every(item => item === 0);
                            var datos = JSON.stringify(formData3, 4, null)
                            let id = window.location.href
                            var url = new URL(id);
                            var categoria = url.pathname
                            var cat = 'Home';
                            var variante = '';
                            var arrayCat = categoria.split('/')

                            if (categoria.includes('atracciones')) {
                                cat = 'Atracciones'
                            }
                            if (categoria.includes('hospedaje')) {
                                cat = 'Hospedaje'
                            }
                            if (categoria.includes('spa')) {
                                cat = 'Spa'
                            }
                            if (categoria.includes('reserva')) {
                                cat = 'Hospedaje'
                            }

                            if (formulario3[index].getAttribute('data-nombre').includes('1.25')) {
                                variante = 'Menores de 1.25m'
                            }
                            if (formulario3[index].getAttribute('data-nombre').includes('90')) {
                                variante = 'Menores de 90cm'
                            }
                            if (formulario3[index].getAttribute('data-nombre').includes('menor') || formulario3[index].getAttribute('data-nombre').includes('Menor')) {
                                variante = 'Menores'
                            }
                            if (!formulario3[index].getAttribute('data-nombre').includes('1.25')) {
                                variante = 'General'
                            }
                            if (formulario3[index].getAttribute('data-nombre').includes('Entrada')) {
                                variante = 'Entradas'
                            }
                            var rowItem = {
                                item_name: formulario3[index].getAttribute('data-nombre'),
                                item_id: formulario3[index].getAttribute('data-sku'),
                                price: formulario3[index].getAttribute('data-precio').replace('$', ''),
                                item_brand: 'Las Estacas',
                                item_category: cat,
                                item_variant: variante,
                                item_list_name: 'Productos ' + cat,
                                item_list_id: 'P' + cat.charAt(0),
                                index: '1',
                                quantity: '1'
                            }
                            arregloItem3.push(rowItem)

                            // Comentado porque puede ser que se necesite un evento por cada producto.
                            // window.dataLayer.push({
                            //     'event': 'productImpressions',
                            //     'item': formulario3[index].getAttribute('data-nombre'),
                            //     'category': cat,
                            //     'brand': 'Las estacas',
                            //     'variants': variante,
                            //     'url': categoria,
                            //     'lista': cat + ' ' + arrayCat[arrayCat.length - 1] + ' ' + formulario3[index].getAttribute('data-componente'),
                            //     ecommerce: {
                            //         items:
                            //             [
                            //                 {
                            //                     item_name: formulario3[index].getAttribute('data-nombre'),
                            //                     item_id: formulario3[index].getAttribute('data-sku'),
                            //                     price: formulario3[index].getAttribute('data-precio').replace('$', ''),
                            //                     item_brand: 'Las estacas',
                            //                     item_category: cat,
                            //                     item_variant: variante,
                            //                     tiem_list_name: cat + ' ' + arrayCat[arrayCat.length - 1] + ' ' + formulario3[index].getAttribute('data-componente'),
                            //                     index: '1',
                            //                     quantity: '1'
                            //                 }]
                            //     }
                            // })
                        }
                        // Envío del evento 'view_item_list'.
                        window.dataLayer.push({ ecommerce: null });
                        window.dataLayer.push({
                            event: 'view_item_list',
                            ecommerce: {
                                items: arregloItem3
                            }
                        })
                    })
                }, 10000);
            }
        });
}