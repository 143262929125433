import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";

//import '../sites/all/themes/tickets/js/_analyticstagmanager';
import '../estacas2021-react/src/sites/all/themes/tickets/js/_analyticstagmanager.js'

if ('serviceWorker' in navigator) {
    navigator.serviceWorker.getRegistrations()
        .then(function (registrations) {
            for (let registration of registrations) {
                if (registration.active.scriptURL == 'https://estacas2021.dev.m22.mx/sw.js') { registration.unregister(); }
            }
        });
}