
import { wrapper } from 'axios-cookiejar-support';
import { CookieJar } from 'tough-cookie';

const axios = require('axios');


export const jar = new CookieJar();
//export const url ="https://silvio.estacas.dev.m22.mx";
export const idGA4 = "G-T7VKKSNJ3M"

// export const url = "https://stg.lasestacas.com"
//stg
// export const url = "https://cmstg.lasestacas.com";
//produccion
 export const url = "https://cms.lasestacas.com";
//export const client = wrapper(axios.create({ jar }));
//export var idUsuario = 0;

//prod
export const gtmCode = "GTM-M6W3BT6"
//stg
// export const gtmCode = "GTM-T6G65BX"